import React, { useState, useEffect } from "react";
import { api } from '../../services/api';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ResponsiveDialog from "../AlertDialog";

export default function IndividualItem(props) {
  const [open, setOpen] = useState(false);
  const [itens, setItens] = useState([]);
  const [setores, setSetores] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [editValidation, setEditValidation] = useState(true);
  const [deleteValidation, setDeleteValidation] = useState(false);

  useEffect(() => {
    api.get("/constants").then((response) => {
      setItens(response.data.todosOsTiposDeItem);
      setSetores(response.data.todosOsSetores);
      setDepartamentos(response.data.todosOsDepartamentos);
    });
  }, []);

  const [editValues, setEditValues] = useState({
    id: props.id,
    type: props.individualCard.Tipo,
    name: props.individualCard.Nome,
    cost: props.individualCard.Valor,
    enterprise: props.individualCard.Setor,
    department: props.individualCard.Departamento,
    responsible: props.individualCard.Responsavel,
    description: props.individualCard.Descrição,
  });

  const handleChangeValues = (values) => {
    setEditValues((prevValues) => ({
      ...prevValues,
      [values.target.id]: values.target.value,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickDeleteOpen = () => {
    setOpen(true);
    setDeleteValidation(true);
  };

  const handleEditButtonClick = () => {
    setEditValidation(false);
  };
  const handleDiscardButtonClick = () => {
    setEditValidation(true);
  };

  const handleSaveButtonClick = async () => {
    try {
      await api
      .put(`/edit/${editValues.id}`, {
        id: editValues.id,
        type: editValues.type,
        name: editValues.name,
        cost: editValues.cost,
        enterprise: editValues.enterprise,
        department: editValues.department,
        responsible: editValues.responsible,
        description: editValues.description,
      })
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteButtonClick = async () => {
    await api.delete(`/delete/${editValues.id}`);
  };

  const handleAddButtonClick = async () => {
    try {
      await api
      .post(`/create`, {
        id: editValues.id,
        type: editValues.type,
        name: editValues.name,
        cost: editValues.cost,
        enterprise: editValues.enterprise,
        department: editValues.department,
        responsible: editValues.responsible,
        description: editValues.description,
      })
    }
    catch(e) {
      console.log(e);
    }
  };

  return (
    <div className="IndividualItem">
          <ResponsiveDialog 
          open={open}
          setOpen={setOpen}
          setEditValidation={setEditValidation}
          handleSaveButtonClick={handleSaveButtonClick}
          handleAddButtonClick={handleAddButtonClick}
          handleDeleteButtonClick={handleDeleteButtonClick}
          deleteValidation={deleteValidation}
          validation={props.validation}
          />
      <Card>
        <CardHeader
          subheader={<p> {props.id} </p>}
          action={
            <CardActions disableSpacing>
              <IconButton aria-label="edit" onClick={handleEditButtonClick}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={handleClickDeleteOpen}>
                <DeleteIcon />
              </IconButton>
            </CardActions>
          }
        />
        <CardContent>
          <Autocomplete
            id="type"
            disabled={editValidation}
            autoFocus
            margin="dense"
            options={itens}
            fullWidth
            sx={{ mb: 1 }}
            value={editValues.type}
            onChange={(event, newValue) => {
              if (newValue === null) {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["type"]: "",
                }));
              } else {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["type"]: newValue.label,
                }));
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Tipo do Item" />
            )}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            disabled={editValidation}
            label="Item"
            defaultValue={editValues.name}
            type="text"
            onChange={handleChangeValues}
            fullWidth
            sx={{ mb: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="cost"
            disabled={editValidation}
            label="Preço"
            defaultValue={editValues.cost}
            type="number"
            onChange={handleChangeValues}
            fullWidth
            sx={{ mb: 1 }}
          />
          <Autocomplete
            id="enterprise"
            disabled={editValidation}
            autoFocus
            margin="dense"
            options={setores}
            fullWidth
            sx={{ mb: 1 }}
            value={editValues.enterprise}
            onChange={(event, newValue) => {
              if (newValue === null) {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["enterprise"]: "",
                }));
              } else {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["enterprise"]: newValue.label,
                }));
              }
            }}
            renderInput={(params) => <TextField {...params} label="Setor" />}
          />
          <Autocomplete
            id="department"
            disabled={editValidation}
            autoFocus
            margin="dense"
            options={departamentos}
            fullWidth
            sx={{ mb: 1 }}
            value={editValues.department}
            onChange={(event, newValue) => {
              if (newValue === null) {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["department"]: "",
                }));
              } else {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["department"]: newValue.label,
                }));
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Departmento" />
            )}
          />
          <TextField
            autoFocus
            margin="dense"
            id="responsible"
            disabled={editValidation}
            label="Responsavel"
            defaultValue={editValues.responsible}
            type="text"
            onChange={handleChangeValues}
            fullWidth
            helperText="Adicione nome e o primeiro sobrenome."
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            disabled={editValidation}
            label="Descrição"
            multiline
            maxRows={4}
            defaultValue={editValues.description}
            type="text"
            onChange={handleChangeValues}
            fullWidth
            sx={{ mb: 1 }}
          />
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            onClick={handleDiscardButtonClick}
            disabled={editValidation}
          >
            Descartar Alterações
          </Button>
          {props.validation ? 
          <Button
                    variant="contained"
                    disabled={editValidation}
                    onClick={handleClickOpen}
                  >
                    Salvar
                  </Button> : 
                    <Button
                    variant="contained"
                    disabled={editValidation}
                    onClick={handleClickOpen}
                  >
                    Cadastrar
                  </Button>
          }
        </CardActions>
      </Card>
    </div>
  );
}
