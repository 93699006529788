import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

export default function ResponsiveDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();

  const handleClose = () => {
    props.setOpen(false);
    props.setEditValidation(true);
    setSuccess(false)
  };

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  const handleLoading = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
      timer.current = window.setTimeout(() => {
        handleClose()
      }, 2500);
    }
  };

  const handleDeleteLoading = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
      timer.current = window.setTimeout(() => {
        handleClose()
        document.location.reload(true);
      }, 2500);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Deseja continuar?"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button autoFocus disabled={loading} onClick={handleClose}>
            Não
          </Button>
          {props.deleteValidation 
          ? 
          <Button
          variant="contained"
          disabled={loading}
          sx={buttonSx}
          onClick={() => {
            handleDeleteLoading();
            props.handleDeleteButtonClick()
          }}
        >
          deletar
        </Button>
          :
          <Button
          variant="contained"
          disabled={loading}
          sx={buttonSx}
          onClick={() => {
            handleLoading();
            props.validation
              ? props.handleSaveButtonClick()
              : props.handleAddButtonClick();
          }}
        >
          Sim
        </Button>
          }
          {/* <Button
            variant="contained"
            disabled={loading}
            sx={buttonSx}
            onClick={() => {
              handleLoading();
              props.validation
                ? props.handleSaveButtonClick()
                : props.handleAddButtonClick();
            }}
          >
            Sim
          </Button> */}
          {loading && (
            <CircularProgress
              size={35}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
