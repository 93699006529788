import React, { useState, useEffect } from "react";
import { api } from '../../services/api';
import * as ReactBootstrap from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./IndividualItemPage.css";
import IndividualItem from "../../components/Individual-Item-Page-Components/IndividualItem";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../../components/Nav-Bar/NavBar";


function IndividualItemPage() {
  const [individualCard, setIndividualCard] = useState([]);
  const [validation, setValidation] = useState();
  const [loading, setloading] = useState(false);
  const params = useParams();

  const IndividualCardAPIRequest = async () => {
    try {
      await api
        .get(`/tag/${params.id}`)
        .then((response) => {
          setIndividualCard(response.data.data);
          setValidation(response.data.validation);
        });
      setloading(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    IndividualCardAPIRequest();
  }, []);

  return (
    <div className="IndividualItemPage">
      <NavBar />
      {loading ? (
          <IndividualItem individualCard={individualCard} id={params.id} validation={validation}/>
      ) : (
        <ReactBootstrap.Spinner animation="border" />
      )}
    </div>
  );
}

export default IndividualItemPage;
