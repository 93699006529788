import React, { useState, useEffect } from "react";
import { api } from '../../services/api';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete } from "@mui/material";

export default function RegistrationDialog(props) {
  const [itens, setItens] = useState([]);
  const [setores, setSetores] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);

  useEffect(() => {
    api.get("/constants").then((response) => {
      setItens(response.data.todosOsTiposDeItem);
      setSetores(response.data.todosOsSetores);
      setDepartamentos(response.data.todosOsDepartamentos);
    });
  }, []);

  const [editValues, setEditValues] = useState({
    id: props.nextID,
    type: props.type,
    name: props.name,
    cost: props.cost,
    enterprise: props.enterprise,
    department: props.department,
    responsible: props.responsible,
    description: props.description,
    data: props.data,
  });

  const handleChangeValues = (values) => {
    setEditValues((prevValues) => ({
      ...prevValues,
      [values.target.id]: values.target.value,
    }));
  };

  const [openFastRegister, setopenFastRegister] = React.useState(false);

  const handleOpen = () => {
    setopenFastRegister(true);
  };
  const handleClose = () => {
    setopenFastRegister(false);
  };

  const handleAddItem = () => {
    api.post(`/create`, {
      id: editValues.id,
      type: editValues.type,
      name: editValues.name,
      cost: editValues.cost,
      enterprise: editValues.enterprise,
      department: editValues.department,
      responsible: editValues.responsible,
      description: editValues.description,
    })
    .then((response) => {
      if(response.data == null){
        alert("The ID Already exists!");
      }
      else{
        alert("Your id is being registered!");
      }
    })
    handleClose();
  };

  const handleRefresh = () => {
    document.location.reload(true);
  }

  return (
    <React.Fragment>
      <Button onClick={handleOpen}> Cadastro Rápido </Button>
      <Dialog
        open={openFastRegister}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cadastrar</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="id"
            label="id"
            defaultValue={props.nextID}
            type="number"
            onChange={handleChangeValues}
            fullWidth
            sx={{ mb: 1 }}
          />
          <Autocomplete
            id="type"
            autoFocus
            margin="dense"
            options={itens}
            fullWidth
            sx={{ mb: 1 }}
            value={editValues.type}
            onChange={(event, newValue) => {
              if (newValue === null) {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["type"]: "",
                }));
              } else {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["type"]: newValue.label,
                }));
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Tipo do Item" />
            )}
          />

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Item"
            defaultValue={props.name}
            type="text"
            onChange={handleChangeValues}
            fullWidth
            sx={{ mb: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="cost"
            label="Preço"
            defaultValue={props.cost}
            type="number"
            onChange={handleChangeValues}
            fullWidth
            sx={{ mb: 1 }}
          />
          <Autocomplete
            id="enterprise"
            autoFocus
            margin="dense"
            options={setores}
            fullWidth
            sx={{ mb: 1 }}
            value={editValues.enterprise}
            onChange={(event, newValue) => {
              if (newValue === null) {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["enterprise"]: "",
                }));
              } else {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["enterprise"]: newValue.label,
                }));
              }
            }}
            renderInput={(params) => <TextField {...params} label="Setor" />}
          />
          <Autocomplete
            id="department"
            autoFocus
            margin="dense"
            options={departamentos}
            fullWidth
            sx={{ mb: 1 }}
            value={editValues.department}
            onChange={(event, newValue) => {
              if (newValue === null) {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["department"]: "",
                }));
              } else {
                setEditValues((prevValues) => ({
                  ...prevValues,
                  ["department"]: newValue.label,
                }));
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Departmento" />
            )}
          />
          <TextField
            autoFocus
            margin="dense"
            id="responsible"
            label="Responsavel"
            defaultValue={props.responsible}
            type="text"
            onChange={handleChangeValues}
            fullWidth
            sx={{ mb: 1 }}
            helperText="Adicione o nome e o primeiro sobrenome."
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Descrição"
            multiline
            maxRows={4}
            defaultValue={props.description}
            type="text"
            onChange={handleChangeValues}
            fullWidth
            sx={{ mb: 1 }}
          />
          <TextField
            disabled
            margin="dense"
            id="data"
            label="Data"
            defaultValue={props.data}
            type="text"
            onChange={handleChangeValues}
            fullWidth
            sx={{ mb: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary"
          onClick={() => {
            handleAddItem();
            handleRefresh();
          }
          }>
            Cadastrar ID
          </Button>
        </DialogActions>
      </Dialog>
      </React.Fragment>
  );
}
