import React, { useEffect, useState } from "react";

function FiveLatestItens(props) {
  return (
    <div className="all-latest-cards">
          <div className="ind-latest-card">
          <div id="upper-section-card">
            <p>{props.id}</p>
            <p>{props.nome}</p>
          </div>
          <div id="bottom-section-card">
            <p>R$ {props.valor}</p>
            <p>{new Date (props.creationData._seconds * 1000).toISOString().substring(0, 10)}</p>
          </div>
        </div>
    </div>
  );
}

export default FiveLatestItens;
