import React, { useMemo, useEffect, useState, useContext } from "react";
import "./styles.css";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from '../../services/api';


function NavBar() {
  const [listCard, setListCard] = useState([]);
  const [busca, setBusca] = useState("");
  const [searchValidation, setsearchValidation] = useState(false);

  const { logout } = useContext(AuthContext)
  const handleLogout = () => {
    logout();
  }

  const latestFunction = async () => {
    try {
      await api
        .get("/base_de_dados")
        .then((response) => {
          setListCard(response.data.data);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    latestFunction();
  }, []);

  const filtrarItems = (listCard, busca) => {
    const lowerBusca = busca.toLowerCase();

    return listCard.filter((item) =>
      item.busca.toLowerCase().includes(lowerBusca)
    );
  };

  const itensFiltrados = useMemo(() => filtrarItems(listCard, busca), [busca]);

  return (
    <>
      {["md"].map((expand) => (
        <Navbar key={expand} bg="blue" expand={expand} className="mb-3" onClick={() => setsearchValidation(true)}
        >
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Brand
              href="https://www.jbesolucoes.com.br/#/"
              target="_blank"
            >
              Grupo JBE
            </Navbar.Brand>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-start flex-grow-1 pe-3" id="navBar-Links">
                  <Nav.Link href="/">Início</Nav.Link>
                  <Nav.Link href="/todos_os_itens">Todos os itens</Nav.Link>
                  <NavDropdown
                    title="Outras Ações"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">
                      Verificação Anual
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action4">Perfil</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item 
                    // href="#action5"
                    onClick={handleLogout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Buscar"
                    className="me-2"
                    aria-label="Search"
                    value={busca}
                    onClick={(e) => {
                    e.stopPropagation()
                    setsearchValidation(false)
                    }}
                    onChange={(ev) => {
                      setBusca(ev.target.value);
                    }}
                  />
                </Form>
                <div className="floating-list-container" hidden={searchValidation} onClick={(e) => {
                    e.stopPropagation() }}>
                  {itensFiltrados.map((itemFiltrado) => (
                    <a href={`/tag/${itemFiltrado.id}`} key={itemFiltrado.id}>
                    <div className="floating-list-item" key={itemFiltrado.id}>
                    <div className="floating-list-left">
                    <div>{itemFiltrado.Nome}</div>
                    <div>{itemFiltrado.id} </div>
                    </div>
                    <div className="floating-list-right">
                    <div>{itemFiltrado.Setor}</div>
                    <div>{itemFiltrado.Responsavel} </div>
                    </div>
                    </div>
                    </a>
                  ))}
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavBar;
