import React, {Component} from "react";
import QrReader from 'react-qr-scanner'
import { isMobile } from 'react-device-detect'
import px2vw from "../../utils/px2vw";

class QrCode extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            delay: 100,
            result: 'Hold QR Code Steady and Clear to Scan',
        }
        this.handleScan = this.handleScan.bind(this)
    }

  handleScan = (data) => {
    if (data !== null){
    this.setState({
      result: data.text,
    });
    let janela = 
    // "https://" + 
    data.text.slice(37)
    window.open(janela, "_self")
  }
  else{
    this.setState({
      result: data,
    });
  }
  
  }

  handleError(err){
    console.error(err)
  }

  render() {
    const previewStyle = {
      height: '100%',
      width: '100%',
      justifyContent: "center",
      marginTop: 15
    }
    const camStyle = {
      display: 'flex',
      justifyContent: "flex-start",
      marginTop: '-50px',
      width: px2vw(1200),
      maxWidth: 600,
      maxHeigt: 550,

    }
    const textStyle = {
      fontSize: '30px',
      justifyContent: "center",
      marginTop: '-50px'
    }

    return (
      <div>
        <div style={camStyle}>
          <QrReader
            delay={this.state.delay}
            style={previewStyle}
            onError={this.handleError}
            onScan={this.handleScan}
            constraints={
              isMobile
              ? {
                video: {
                    facingMode: { exact: `environment` }
                }
              }
              : undefined
              }
          />
        </div>
        <p style={textStyle}>
          {this.state.result}
        </p>
        </div>
    );
  }
}

export default QrCode
