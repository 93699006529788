import React, { useEffect, useState } from "react";
import AllCards from '../components/All-Itens-Page-Components/AllCards'
import * as ReactBootstrap from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../components/Nav-Bar/NavBar";
import { api } from '../services/api';


function AllItens() {
  const [listCard, setListCard] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    latestFunction();
  }, [])

  const latestFunction = async () => {
    try {
      await api.get("/base_de_dados").then((response) => {
        setListCard(response.data.data);
    });
    setloading(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="app-container">
      <NavBar />
      <h1 className="register-title">Todos os Ativos</h1>
      {loading 
      ? 
      <div className="all-cards">
      {listCard.map((val) => (
      <AllCards
        listCard={listCard}
        setListCard={setListCard}
        key={val.id}
        id={val.id}
        type={val.Tipo}
        name={val.Nome}
        cost={val.Valor}
        enterprise={val.Setor}
        department={val.Departamento}
        responsible={val.Responsavel}
        description={val.Descrição}
        data={val.Data}
      />
    ))}
       </div>
      :
      <ReactBootstrap.Spinner animation="border" /> 
      }
    </div>
  );
      }




export default AllItens;
