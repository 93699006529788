import React from 'react';
import inventory from '../../icons/inventory.svg';
import money from '../../icons/moedas.svg';


function GeneralInfocards(props) {

    return (
        <>
        <div className="upper-containers-wrap">
        <div className="upper-left-container"> 
        <p className='d-flex justify-content-start mb-4'>Itens Cadastrados</p>
        <div className="upper-info-wrap">
        <div className="upper-container-values">{props.contador}</div>
        <img src={inventory} className="inventory-icon" alt="Icone representando um inventario" />
        </div>
    </div>
    <div className='upper-right-container'>
        <p className='d-flex justify-content-start mb-4'>Valor total</p>
        <div className="upper-info-wrap">
        <div className="upper-container-values">R$ {props.valor}</div>
        <img src={money} className="money-icon" alt="Icone representando moedas" />
        </div>
    </div>
    </div>
</>
)}

  export default GeneralInfocards;