import * as React from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import RegistrationDialog from "./RegistrationDialog";
import QrCode from "./QrCode"
import px2vw from "../../utils/px2vw";

const style = {
  position: "absolute",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'space-between',
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: px2vw(1000),
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 3,
};

function QrCodeModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button onClick={handleOpen}>Ler QR Code</Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Card sx={{ ...style, width: px2vw(1368), maxWidth: 700, height: 550 }}>
          <CardHeader
            title="Leitor QR Code"
            action={
              <IconButton aria-label="delete" onClick={handleClose} >
                <CloseIcon  />
              </IconButton>
            }
          />
          <CardContent>
             <QrCode />
          </CardContent>
        </Card>
      </Modal>
    </React.Fragment>
  );
}


export default function ModalAddButton(props) {
    const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    props.setOpenModal(false);
  };

  return (
    <div>
      <Modal
        open={props.openModal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Card sx={{ ...style, maxWidth: 600 }}>
          <CardHeader
            title="Deseja Cadastrar Novo Ativo?"
            action={
              <IconButton aria-label="delete" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Escolha abaixo a ação desejada:
            </Typography>
          </CardContent>
          <CardActions>
          <QrCodeModal />
          <RegistrationDialog open={open} setOpen={setOpen} nextID={props.nextID} />
          </CardActions>
        </Card>
      </Modal>
    </div>
  );
}
