import React, { useContext } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import AllItens from "./pages/Allitens";
import IndividualItemPage from "./pages/IndividualItemPage/IndividualItemPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import SignUpPage from "./pages/Sign-Up-Page/SignUpPage";

import { AuthProvider, AuthContext } from "./contexts/AuthContext";

function AppRoutes() {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading){
      return <div className="loading">Carregando...</div>;
    }
    if (!authenticated) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/signUp" element={<SignUpPage />} />
          <Route
            exact
            path="/"
            element={
              <Private>
                <Home />
              </Private>
            }
          />
          <Route
            exact
            path="todos_os_itens"
            element={
              <Private>
                <AllItens />
              </Private>
            }
          />
          <Route
            exact
            path="tag/:id"
            element={
              <Private>
                <IndividualItemPage />
              </Private>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default AppRoutes;
