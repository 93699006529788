import React, { useState, useEffect } from "react";
import GeneralInfocards from "../components/Home-Page-Components/GeneralInfocards";
import FiveLatestItens from "../components/Home-Page-Components/FiveLatestItens";
import addButton from "../icons/add-icon.svg";
import * as ReactBootstrap from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalAddButton from "../components/Home-Page-Components/ModalAddButton";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../components/Nav-Bar/NavBar";
import {api, createSession, createUser} from '../services/api';

function Home() {
  const [openModal, setOpenModal] = useState(false);
  const [ultimosCards, setUltimosCards] = useState([]);
  const [loading, setloading] = useState(false);
  const [contador, setContador] = useState([]);
  const [valor, setValor] = useState([]);
  const [nextID, setnextID] = useState([]);

  const latestCardsAPIRequest = async () => {
    try {
      await api
        .get("/ultimos_adicionados")
        // .get("https://gestao-de-ativos-server.fly.dev/ultimos_adicionados")
        .then((response) => {
          setUltimosCards(response.data);
        });
      await api
        .get("/base_de_dados")
        // .get("https://gestao-de-ativos-server.fly.dev/base_de_dados")
        .then((response) => {
          setContador(response.data.tamanho);
          setValor(response.data.valorTotal);
          setnextID(response.data.nextID);
        });
      
      setloading(true);
    } catch (e) {
      console.log(e);
    }
  };



  useEffect(() => {
    latestCardsAPIRequest();
  }, []);

  return (
    <div className="Home">
    <NavBar />
      {loading ? (
        <div className="App">
            <GeneralInfocards
            contador={contador}
            valor={valor}
            />
            <div className="empresas">
            {/*Create a section with individual info from the enterprises: IDs, Value, Percentage Graph*/}
          </div>
          <div className="middle-section">
            <div className="upper-middle-section">
              <p> Ultimos Adicionados</p>
              <Link to="/todos_os_itens" className="App-link">
                Ver mais
              </Link>
            </div>
            {ultimosCards.map((latestCardsData) => (
            <FiveLatestItens
            key={latestCardsData.id}
            id={latestCardsData.id}
            nome={latestCardsData.Nome}
            valor={latestCardsData.Valor}
            creationData={latestCardsData.Data}
            />
          ))}
          </div>
          <div className="bottom-section">
            <button
              id="big-screen-button"
              onClick={() => {
                setOpenModal(true);
              }}
              className="bg-add-button"
            >
              {" "}
              Adicionar Item
            </button>
            <img
              id="small-screen-button"
              src={addButton}
              alt="Icone de botão, com um símbolo representando adição"
              onClick={() => {
                setOpenModal(true);
              }}
              className="add-button"
            />
            {openModal && <ModalAddButton openModal={openModal} nextID={nextID} closeModal={setOpenModal} setOpenModal={setOpenModal}/>}
          </div>
        </div>
      ) : (
        <ReactBootstrap.Spinner animation="border" />
      )}
    </div>
  );
}

export default Home;
