import axios from "axios";
const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : process.env.REACT_APP_BASE_URL;

export const api = axios.create({
  baseURL,
});

export const createSession = async (email, password) => {
  return api.post("/sessions", { email, password });
};

export const createUser = async (name, email, password) => {
  return api.post("/users/create", { name, email, password });
};
