import React from "react";
import "./AllCards.css";
import { Link } from "react-router-dom";

function AllCards(props) {
  return (
    //
    <div className="wrapper">
      <Link to={`/tag/${props.id}`} className="App-link">
      <div className="ind-card">
        <div className="upper-card-section">
          <span>{props.type}</span>
        </div>
        <div className="middle-card-section">
          <p className="card-cartegory">R$ {props.cost}</p>
          <p> | </p>
          <p className="card-id">{props.id}</p>
        </div>
        <p id="item-name">{props.name}</p>
      </div>
      </Link>
    </div>
  );
}

export default AllCards;
