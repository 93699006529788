import React, { useState, useContext }from "react";
import "./styles.css";
import { AuthContext } from "../../contexts/AuthContext";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import MoodIcon from '@mui/icons-material/Mood';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

function SignUpPage() {
    const { signUp } = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        signUp(name, email, password); // integração com o contexto // API
    }

  return (
    <div id="signUp">
      <div id="signUpContainer">
      <h1 className="title">Sign Up</h1>
      <form onSubmit={handleSubmit} className="form">
      <TextField 
        id="name" 
        value={name} 
        onChange={(e) => setName(e.target.value)} 
        label="Nome"
        fullWidth
        margin="dense"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MoodIcon />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      <TextField
        type="email"
        name="email" 
        id="email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        label="E-mail"
        fullWidth
        margin="dense"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
        <TextField
        type="password" 
        name="password" 
        id="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)}
        label="Senha"
        fullWidth
        margin="dense"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
        <div className="actions">
          <Button
          type="submit"
          variant="contained"
          id="submit-button"
          fullWidth
          >
          Crie sua conta
          </Button>
        </div>
      </form>
      </div>
    </div>
  );
}

export default SignUpPage;